import { getBlogHome, getBlogListing } from 'lib/api'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic';

const DynamicBlogListing = dynamic(() => import('components/BlogListing'));
const DynamicBlogHome = dynamic(() => import('components/BlogHome'));

export default function BlogHome(props) {
  const router = useRouter();
  const query = router?.query?.slug;
  if (!query) {
    return <DynamicBlogHome {...props} />
  } else {
    return <DynamicBlogListing {...props} />
  }
}

export const getServerSideProps = async (context, preview, previewData) => {
  let query = context?.query?.slug;
  let props;
  if (!query) {
    props = await getBlogHome({
      active: preview,
      token: previewData?.token,
    })
  } else {
    props = await getBlogListing(query, {
      active: preview,
      token: previewData?.token,
    })
    const filteredArticles =
      query === 'all'
        ? props?.blog.articles
        : props?.blog?.articles?.filter((article) => article?.tags?.some((tag) => tag?.slug === query))

    if (filteredArticles?.length < 1) {
      return {
        notFound: true,
      }
    }
  }

  return {
    props: {
      ...props,
      // countryCode
    },
  }
};
